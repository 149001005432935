/* Icons */

/* Menu Icons | bootstrap icons */
.b-icon{
    font-size: 25px;
    color: #fff;
    line-height: 1;
}
/* Menu icons */
.m-icon{
    width: 14px;
    height: 14px;
    margin: 0 10px 0 0px;
    display: inline-block;
    background-size: contain !important;
}
/* Mobile menu icons */
.mm-icon{
    width: 14px;
    height: 14px;
    display: block;
    background-size: contain !important;
    padding: 10px;
}

.mm-icon-1{
    width: 20px;
    height: 20px;
}

.info-icon{
    background: url('~/images/info-icon.svg') center center no-repeat;
    width: 14.62px;
    height: 14.62px;
    display: inline-block;
}

.dna-play-icon{
    background: url('~/images/dna-play-icon.svg') center center no-repeat;
    width: 12px;
    height: 12px;
    display: inline-block;
    background-size: contain;
}
.dna-carousel-icon{
    background: url('~/images/dna-carousel-icon.svg') center center no-repeat;
    width: 12px;
    height: 12px;
    display: inline-block;
}

.unread-icon{
    background: url('~/images/unread-dot.svg') center center no-repeat;
    width: 8px;
    height: 8px;
    display: inline-block;
}


.download-icon{
    background: url('~/images/download.svg') center center no-repeat;
    width: 40px;
    height: 40px;
    display: inline-block;
}

.send-text{
    background: url('~/images/send-text.svg') 27% 32% no-repeat;
    background-color: $secondary;
    border-radius: 50%;
    padding: 0.8rem;
    display: inline-block;
}

.media-attachment{
    background: url('~/images/camera.svg') center center no-repeat;
    display: inline-block;
    width: 25.59px;
    height: 25.59px;
    background-size: contain;
}

.file-icon {
    width: 32px;
    height: 32px;
    background: url('~/images/file-placeholder.svg') no-repeat;
    display: inline-block;
    float: left;
    margin-right: 5px;
    &.image-file {
        background: url('~/images/image-gallery.svg') center center no-repeat;
        background-size: contain;
    }
}

.dash-square-icon{
    background: url('~/images/dash-square.svg') center center no-repeat;
    width: 25px;
    height: 25px;
    display: inline-block;
    margin: 1px;
}
.plus-square-icon{
    background: url('~/images/plus-square.svg') center center no-repeat;
    width: 25px;
    height: 25px;
    display: inline-block;
    margin: 1px;
}

.dots-icon{
    background: url('~/images/dots-icon.svg') center center no-repeat;
    width: 20px;
    display: inline-block;
    height: 6px;
}

.icon-chevron-left{
    background: url('~/images/chevron-left-wide.svg') center center no-repeat;
    width: 33px;
    display: inline-block;
    height: 41px;
    opacity: 0.7;
    &:hover {
        opacity: 1;
    }
}

.logout-icon{
    background: url('~/images/logout-icon.png') no-repeat;
}
.my-feeds-icon{
    background: url('~/images/my-feeds-icon.svg') no-repeat;
}

.campaigns-icon{
    background: url('~/images/campaigns.svg') no-repeat;
}

.green-tick-icon{
    background: url("~/images/green-tick.svg") no-repeat;
    display: inline-block;
    width: 20px;
    height: 20px;
}

.tick{
    &.white{
        background: url("~/images/tick-wite.svg") no-repeat;
        width: 10px;
        height: 8px;
        display: inline-block;
    }
    &.orange{
        background: url("~/images/tick-orange.svg") no-repeat;
        width: 10px;
        height: 8px;
        display: inline-block;
    }
}

.tick-icon{
    background: url("~/images/tick-orange.svg") no-repeat;
    width: 10px;
    height: 8px;
    display: inline-block;
}
.tick-icon-btn{
    &:hover{
        .tick-icon{
            background: url("~/images/tick-wite.svg") no-repeat;
        }
    }
}
.x-icon{
    background: url("~/images/x-orange-icon.svg") no-repeat;
    width: 9px;
    height: 10px;
    display: inline-block;
}
.x-icon-btn{
    &:hover{
        .x-icon{
            background: url("~/images/x-white-icon.svg") no-repeat;
        }
    }
}

.cross{
    display: inline-block;
    &.orange{
        width: 9px;
        height: 10px;
        background: url("~/images/x-orange-icon.svg") no-repeat;
    }
    &.white{
        width: 9px;
        height: 10px;
        background: url("~/images/x-white-icon.svg") no-repeat;
    }
    &.red-circle{
        width: 27px;
        height: 26px;
        background: url("~/images/cross-icon.svg") no-repeat;
    }
}

.cross-icon{
    background: url("~/images/cross-icon.svg") no-repeat;
}

.white-tick-icon{
    background: url("~/images/white-tick.svg") no-repeat;
    width: 49px;
    height: 50px;
    &.outline{
        background: url("~/images/white-tick-outline.svg") no-repeat;
        width: 34px;
        height: 34px;
    }
    display: inline-block;
}

.star{
    background: url("~/images/star.svg") no-repeat;
    &.star-0{
        background: url("~/images/star-grey.svg") no-repeat;
    }
    width: 11px;
    height: 11px;
    display: inline-block;
}
.share-icon{
    background: url("~/images/share-icon.svg") no-repeat;
    width: 14px;
    height: 14px;
    display: inline-block;
}

.payment-icon{
    background: url("~/images/payments.svg") no-repeat;
    height: 14px;
    width: 14px;
    display: inline-block;
    background-size: contain;
}

.edit-icon{
    background: url("~/images/edit.svg") no-repeat;
    width: 14px;
    height: 14px;
    display: inline-block;
    &.grey{
        background: url("~/images/edit-icon.svg") no-repeat;
    }
}


.orange-tick-icon{
    background: url("~/images/orange-tick.svg") no-repeat;
}
.proposals-icon{
    background: url('~/images/proposals-icon.svg') no-repeat;
}

.contracts-icon{
    background: url('~/images/contracts-icon.svg') no-repeat;
}

.messages-icon{
    background: url('~/images/messages-icon.svg') no-repeat;
}
/*.chevron-left-icon{
    background: url('~/images/chevron-right.svg') no-repeat;
    transform: rotate( 180deg );
    &.white{
        background: url('~/images/chevron-left.svg') no-repeat;
        transform: rotate( 0deg );
    }
    width: 8px;
    display: block;
    height: 12px;
}*/

.notifications-icon{
    background: url('~/images/bell.svg') center center no-repeat;
    width: 18px;
    height: 18px;
    display: block;
    &.new{
        background: url('~/images/bell-unread.svg') center center no-repeat;
    }
}

.chevron-left-icon{
    background: url('~/images/chevron-left.svg') no-repeat;
    width: 10px;
    display: block;
    height: 15px;
}

.clickable[aria-expanded="true"]{
    .chevron-right-icon{
        transform: rotate( -90deg );
    }
}

.chevron-right-icon{
    background: url('~/images/chevron-right.svg') no-repeat;
    width: 10px;
    display: block;
    height: 12px;
    transition: transform .1s ease-out;
    &.expanded{
        transform: rotate( 90deg );
    }
}
.chevron-down-icon{
    background: url('~/images/chevron-right.svg') no-repeat;
    transform: rotate( 90deg );
    width: 12px;
    display: block;
    height: 8px;
}

.plus-icon{
    background: url('~/images/plus.svg') no-repeat;
    width: 14px;
    display: block;
    height: 13px;
}
.plus-icon-orange{
    background: url('~/images/plus-orange.svg') no-repeat;
    display: inline-block;
    height: 33px;
    width: 33px;
}

.mobile-menu-icon{
    width: 31px;
    display: inline-block;
    height: 31px;
    background: url('~/images/mobile-menu-icon.svg') no-repeat;
}
.menu-icon{
    background: url('~/images/menu-icon.svg') no-repeat;
    width: 25px;
    display: block;
    height: 15px;
}

.file-icon{
    background: url('~/images/file-icon.svg') no-repeat;
    width: 32px;
    height: 40px;
    display: inline-block;
}
.three-dots-icon{
    color: #C4C4C4;
    &:hover{
        color: #C4C4C4;
    }
}

.search-icon{
    background: url('~/images/search-icon.svg') no-repeat;
    width: 15px;
    height: 16px;
    display: inline-block;
}

.nav-item.active{
    .my-feeds-icon{
        background: url('~/images/my-feed-active-icon.svg') no-repeat;
    }

    .proposals-icon{
        background: url('~/images/proposal-active-icon.svg') no-repeat;
    }

    .contracts-icon{
        background: url('~/images/contract-active-icon.svg') no-repeat;
    }

    .messages-icon{
        background: url('~/images/message-active-icon.svg') no-repeat;
    }
}

@import 'icons-media-queries';