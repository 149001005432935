
// Typography
$font-family-sans-serif: 'Montserrat', 'Nunito', sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #B5683B;
$grey: #8C8C8C;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;

/* -------begin customization-------- */

/* simply assign the value */
// Body
$body-bg: #fff;
$body-bg-black: #101010;
$primary: #230344;
$secondary: #E1F05B;
$secondary1: #F3F39E;
// primary font color
$pfc: #230344;

$body-color: #000000;
/* use a variable to override primary */
$theme-colors: (
    primary: #230344,
    secondary: #E1F05B
);

/* form customization*/
$input-color: #95A0AE;
$input-bg: #FFFEFD;
$input-border-radius:6px;
$input-border-color: #C5C5C5;
$border-radius: 6px;

/*card customization*/
$card-bg:#101010;
$card-border-width:0px;
/* -------end customization-------- */


/* Custom Variables */
$nav-link-active : $secondary1;
$nav-link : #fff;
$text-muted : #AAAAAA;
$text-success : #0B9908;

// Calender colots
$is-in-range: $secondary1;
$is-end-date: $secondary1;