// Variables
@import 'variables';

/* Global syling */
.container-card {
  margin-top: 1px;
  border-radius: 0px;
}

.text-muted {
  color: $text-muted;
}

.body-bg-black {
  background: $body-bg-black;
}

.body-bg-primary {
  background: $primary;
}

.testing-alert {
  position: fixed;
  bottom: 0;
  right: 10px;
  z-index: 6;
  width: 130px;
  border-radius: 0;
  text-align: center;
  font-size: 12px;
  line-height: 0.5;
  padding: 10px;
}

._size15{
  width: 15px !important;
  height: 15px !important;
}

/* Anchor tag */
.link-black {
  color: #000;

  &:focus,
  &:hover {
    color: #000;
    text-decoration: none;
  }
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, .25);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;

  &:checked {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    background-color: #0d6efd;
    -webkit-background-color: #0d6efd;
    border-color: #0d6efd;
    -webkit-border-color: #0d6efd;
  }
}

select.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  background-color: #fff;
  background-image: url("~/images/select-arrow.svg");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 7.96px 4.24px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Font weight */
.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semi-bold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-u-bold {
  font-weight: 800 !important;
}

.vh-100 {
  height: 100vh !important;
}

.feature-pop-up {
  display: none;
}

[v-cloak] {
  display: none;
}

.mb-63 {
  margin-bottom: 63px;
}

.d-grid {
  display: grid;

  &.a-4 {
    grid-template-columns: auto auto auto auto;
  }
}

.center {
  margin: 0;
  position: relative;
  left: 0;
  right: 0;
}

/* Custom select style*/
.b-select {
  display: grid;
  position : relative;

  select:disabled {
    background-color: #fff;
  }

  button.form-control:focus {
    background-color: transparent !important;
  }

  select,
  .b-select-btn {
    grid-column: 1;
    grid-row: 1;
    background-color: transparent;
  }

  .btn {
    text-align: left;
    border: 1px solid #c5c5c5;
    color: #95A0AE;
    height: 37px;
  }

  .b-select-btn[aria-expanded="true"]+.b-select-container {
    display: block;
    box-shadow: 0 8px 6px -6px #000;
  }

  .b-select-btn+.b-select-container {
    display: none;
  }

  .b-select-container {
    overflow: hidden;
    position: absolute;
    transform: translate3d(0px, 37px, 0px);
    top: 0px;
    left: 0px;
    will-change: transform;
    width: 100%;
    border: 1px solid #c5c5c5;
    padding: 0;
    border-radius: 6px;
    margin-top: 1px;
    background-color: #fff;
    z-index: 4;

    .b-select-searchbox {
      padding: 4px;
    }

    .b-select-options-container {
      max-height: 300px;
      overflow: auto;

      .b-select-options {
        border: 0;
        margin-bottom: 0;
        list-style: none;
        padding: 0;

        &:hover {
          cursor: pointer;
        }

        .b-select-option {
          /*background-color: #fff;*/
          padding: 3px 6px;
          font-size: 0.8rem;

          &.selected {
            background-color: $secondary;
            color: #000;
          }

          &:hover:not(.selected) {
            color: #000;
            background-color: #e3e3e3;
          }
        }
      }
    }
  }
}

.scrollable-badges {
  width: 90%;
  grid-column: 1;
  grid-row: 1;
  position: absolute;
  top: 6px;
  left: 5px;
  right: 0;
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding: 5px;
  z-index: 2;
  scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
}

.scrollable-badges::-webkit-scrollbar {
  display: none; /* WebKit (Chrome, Safari, etc.) */
}

.scrollable-badges .badge {
  margin-right: 5px;
}

/*Bootstrap-Select custom style*/
.btn-light,
.btn-light:hover,
.btn-light:active {
  background-color: transparent;
  border-color: #c5c5c5;
  color: #95A0AE;
}

.bootstrap-select>select.mobile-device:focus+.dropdown-toggle,
.bootstrap-select .dropdown-toggle:focus {
  outline: none !important;
  -moz-outline: none !important;
  box-shadow: none;
  -moz-box-shadow: none;
  border-color: #c5c5c5;
  background-color: transparent;
  color: #95A0AE;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show>.btn-light.dropdown-toggle:focus {
  box-shadow: none;
  -moz-box-shadow: none;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show>.btn-light.dropdown-toggle {
  background-color: transparent;
  color: #95A0AE;
  border-color: #c5c5c5;
}

#toast-container {
  .toast-close-button {
    top: -.1em;
  }

  .toast-error {
    background-color: #F8D7DA;
    color: #721c24;

    .toast-progress {
      background-color: #721c24;
    }

    .toast-close-button {
      color: #721c24;
    }
  }

  .toast-warning {
    background-color: #FDF3CD;
    color: #856404;

    .toast-progress {
      background-color: #856404;
    }

    .toast-close-button {
      color: #856404;
    }
  }

  .toast-success {
    background-color: #D4EDDA;
    color: #155724;

    .toast-progress {
      background-color: #155724;
    }

    .toast-close-button {
      color: #155724;
    }
  }

  .toast-info {
    background-color: #d1ecf1;
    color: #0c5460;

    .toast-progress {
      background-color: #0c5460;
    }

    .toast-close-button {
      color: #0c5460;
    }
  }

  .toast-message {
    font-size: 11px;
  }

  .toast {
    padding: 15px;
  }

}


.hide-by-default {
  display: none;
}

.outline-0 {
  outline: none !important;
  -moz-outline: none !important;
  box-shadow: none;
  -moz-box-shadow: none;

  /*border-color: inherit;*/
  &:focus,
  &:active {
    /*border-color: inherit;*/
    outline: none !important;
    -moz-outline: none !important;
    box-shadow: none;
    -moz-box-shadow: none;
  }
}

.loading {
  background: transparent;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1051;

  .dna-spinner {
    color: $primary;
    border: 0.15em solid $primary;
    border-right-color: transparent;
    width: 3rem;
    height: 3rem;
  }
}

.blur-2px,
.initial-blur-2px {
  backdrop-filter: blur(2px);
}

.info-tooltipp {
  position: fixed;
  width: 100%;
  display: flex;
  justify-content: center;
  bottom: 52px;
  font-size: 10px;
  transition: bottom .3s;
  z-index: 1;

  div {
    padding: 5px 10px;
    min-height: 10px;
    background: #000;
    color: #fff;
    width: auto;
    max-width: 80%;
    line-height: 1.5;
    border-radius: 6px;
  }
}

small {
  font-size: 9px;
}

.login-box {
  color: #fff;
  position: relative;
  overflow: hidden;
  a,
  .btn-link {
    color: $blue;
    text-decoration: none;
  }

  .btn:focus,
  .btn:active {
    outline: none !important;
    box-shadow: none;
  }

  .invalid-feedback {
    font-size: 13px;
  }

  .form-check {
    line-height: 1
  }

  .form-check-input {
    margin-top: .1rem
  }

  .form-check [type="checkbox"]:checked+label:after {
    border-color: $secondary;
    background-color: $secondary;
  }

  .card {
    background-position: top left;
    background-repeat: no-repeat;
    background-color: $primary;
    /*min-height: 774px;*/
    width: 375px;
    padding-top: 25px;

    .card-header {
      color: #fff;
      background-color: $primary;
      margin-top: 80px;

      .header-text {
        line-height: 35px;
        font-size: 2em;
        font-weight: 800;
        margin-bottom: 22px;
      }

      .back-btn {
        margin-bottom: 42px;
      }
    }

    .card-body {
      background-color: $primary;
    }

    .card-footer {
      .mbh {
        margin-bottom: 100px;
        z-index: 1;
      }

      background-position: bottom right;
      background-repeat: no-repeat;
      min-height: 120px;
      background-color: $primary;
    }
  }

  .form-control {
    background-color: #2f0954;
    border-radius: 18px;
    color: #4c4c4c;
    border: 1px solid #2f0954;

    &.is-invalid {
      border-color: #e3342f;
    }
  }

  .btn-primary {
    border-radius: 18px;
  }

  .btn-orange {
    border-radius: 19px;
    width: 110px;
  }
}

/* Notification Center */
.icon-container {
  position: relative;
}

.h-arrow.show {
  display: block;
  z-index: 3;
}

.h-arrow {
  display: none;
  width: 0px;
  position: absolute;
  height: 0px;
  border-top: 4px solid transparent;
  border-bottom: 10px solid #fff;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  left: 1px;
}

.notifications-container.show {
  .nt-body {
    max-height: 44vh;
    height: auto;
    overflow: auto;
  }

  z-index: 16;
  display: block;
  position: fixed;
  top: 58px;
  left: 18px;
  width: 280px;
  min-height: 100px;
  max-height: 60vh;
  overflow: hidden;
  background: #fff;
  box-shadow: 1px 3px 20px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  margin-left: 0;
  margin-right: 0;
}

.notifications-container {
  .nt-spinner {
    color: $grey;
    line-height: 2;
  }

  display: none;
  position: absolute;

  .nt-header {
    height: 60px;

    .nt-title {
      font-size: 16px;
      line-height: 11px;
      font-weight: 600;
      color: #8C8C8C;
      margin-left: 1.5rem;
    }

    .nt-count {
      width: 24px;
      height: 24px;
      background: #B97247;
      border-radius: 5px;
      font-size: 12px;
      line-height: 11px;
      color: #fff;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 1.5rem;
    }
  }

  .nt-body {
    .empty-notification-list {
      color: #8C8C8C;
    }

    padding: 0.75rem 1.5rem;

    .notification-list {
      .notification {
        margin-bottom: 12px;

        .time {
          color: #8C8C8C;
          font-size: 8.2px;
          font-weight: 500;
        }

        .username {
          font-size: 12px;
          font-weight: 600;
          line-height: 14.96px;
          letter-spacing: 0.03em;
        }

        .status {
          font-size: 10px;
          color: #8C8C8C;
          line-height: 14.96px;
          font-weight: 500;
          letter-spacing: 0.03em;
          word-wrap: break-word !important;
          white-space: normal;

          a {
            color: inherit;
          }
        }
      }
    }

    .load-more {
      color: #8c8c8c;
      font-size: 9px;
      margin-top: 10px;
      text-align: center;
    }
  }

  .nt-footer {
    .btn {
      width: 90%
    }
  }
}

.onboarding-p {
  .upload-container {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 10px 0;
}

.file-type {
    background-color: #e3e3e3;
    padding: 5px 10px;
    border-radius: 3px;
    font-family: Arial, sans-serif;
    font-size: 14px;
    margin-right: 10px;
}

.progress-container {
    flex-grow: 1;
    background-color: #f3f3f3;
    border-radius: 3px;
    overflow: hidden;
    position: relative;
}

.progress-bar {
    height: 20px;
    background-color: $secondary;
    width: 0;
    color: $primary;
    position: relative;
}

.file-name {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: $primary;
    font-family: Arial, sans-serif;
    font-size: 12px;
    line-height: 20px;
}

.cancel-btn {
  background-color: transparent;
    border: 2px solid #e3e3e3; /* Adjusted to a darker shade of gray */
    color: #bcbcbc; /* Adjusted to match border color */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    cursor: pointer;
    font-family: Arial, sans-serif;
    font-size: 14px;
    line-height: 1; /* Adjusted line-height to 1 */
    padding: 0; /* Removed padding */
}

  .progress-line {
    height: 14px;
    width: 100%;
    color: #fff;
    font-size: 11px;
    padding: 3px;
    background-color: #fff;
    background: linear-gradient(to right, $secondary 0% 0%, #fff 0% 100%);
  }
  .first-p-card {
    border-radius: 20px;
    max-width: 50%;
    margin: auto;
    text-align: center;
    ol{
      display: flex;
      text-align: left;
      margin: auto;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    img{
      width: 40px;
    }
  }
  // transitions
  .slide-fade-enter-active {
    transition: all 0.3s ease-in;
  }
  .slide-fade-leave-active {
    transition: all 0.3s ease-out;
  }
  .slide-fade-enter-from {
    transform: translateX(250px);
    opacity: 0;
    &.back{
      transform: translateX(-250px);
    }
  }
  .slide-fade-leave-to {
    transform: translateX(-250px);
    opacity: 0;
    &.back {
      transform: translateX(250px);
    }
  }

  .slide {
    width: 100%;
    position: absolute;
  }

  .notification {
    background-color: #101010;
    color: #fff;
    padding: 4px 20px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    position: fixed;
    bottom: 20px;
    left: 50%;
    text-align: center;
    transform: translateX(-50%);
    transition: bottom 0.5s ease-in-out, opacity 0.5s ease-in-out;
  }

  .notification.hidden {
    bottom: -100px; /* Move the notification off-screen */
    opacity: 0;
  }
  /* Adjust the color on hover (optional) */
  input[type="range"]{
    height: 15px !important;
  }

  .circle {
    position: absolute;
    top: -64px;
    width: 124px;
    height: 124px;
    border-radius: 50%;
    background: conic-gradient($secondary 0deg, $secondary 90deg, $primary 90deg, $primary 270deg, $secondary 270deg); /* Adjust colors as needed */
    transition: transform 0.2s ease; /* Smooth transition for rotation */
  }
  
  .circle-w {
    position: absolute;
    top: -64px;
    width: 124px;
    height: 124px;
    border-radius: 50%;
    transition: transform 0.2s ease; /* Smooth transition for rotation */
  }

  .back-btn {
    position: absolute;
    top: 140px;
    transition: all 0.5s ease;
    z-index: 2;
  }
  .header{
    border-bottom: 2px dashed;
    height: 60px;
    .logo {
      color: $primary;
      font-size: 20px;
    }
    .apollo-avatar{
      position: absolute;
      left: 0;
      width: 100%;
      margin-top: 60px;
      justify-content: center;
      display: flex;
      span {
        z-index: 3;
        display: inline-block;
        background-image: url('~/images/apollo_1.png'); /* Replace 'your_image_url.jpg' with the URL or path to your image */
        background-size: cover; /* Adjust as needed */
        width: 80px;
        height: 80px;
        border-radius: 50%; /* Make it round */
        overflow: hidden; /* Ensure the background image doesn't overflow */
        text-decoration: none; /* Remove default underline */
        color: #fff; /* Set text color */
        text-align: center; /* Center text */
        line-height: 100px; /* Adjust line height to vertically center text */
        position: absolute;
        bottom: -40px
      }
      b{
        position: absolute;
        top: 40px;
        font-size: 8px;
        left: 7px;
        transform: rotate(108deg);
        z-index: 2
      }
    }
    .icons{
      background-color: #fff;
      z-index: 2;
      i {
        background-size: 20px;
        width: 20px;
        height: 20px;
      }
    }
    
  }
  .content{
    .c-header{
      h1 {
        text-align: center;
      }
    }
    .c-body {
      .input-group{
        // max-width: 314px;
      }
      .form-control{
        &::placeholder {
          color: #95A0AE;
          opacity: 1; /* Firefox */
        }
        &::-ms-input-placeholder { /* Edge 12 -18 */
          color: #95A0AE;
        }
      }
      
      .b-select {
        .b-select-container {
          // transform: translate3d(0px, 30px, 0px);
        }
      }
      &.step-5 {
        .form-group.fix-w{
          min-width: 235px;
        }
        .invalid-feedback.fix-h{
          height: 20px
        }
      }
      margin-top: 3rem;
      .price-input-grp {
        min-height: 20px;
        .dollar-sign {
          border: 1px solid #e3e3e3;
          border-right: none !important;
          height: 20px;
        }
        .form-control{
          border-left: none;
          border-radius: 0;
        }
        .price-input {
          height: 20px;
          border-color: #e3e3e3;
          &:active {
            border-color: #e3e3e3;
          }
          &:focus {
            border: 1px solid;
            border-left: none;
            border-color: #e3e3e3;
          }
        }
      }
    }
    .custom-control-input {
      
      &:focus ~ .custom-control-label::before {
        border: none;
        box-shadow: none;
      }

      &:checked ~ .custom-control-label::before{
        border: 2px solid $primary;
        border-radius: 8px;
      }
      
      &:not(:disabled):active ~ .custom-control-label::before{
        background-color: #f3f3b6;
        border: none;
      }
    }
    .custom-radio .custom-control-input{
      &:checked ~ .custom-control-label::after {
        background-image: none;
      }
      &:checked ~ .custom-control-label::before {
        background-color: $secondary;
      }
    }
    .options {
      .form-check-input:checked {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23230344' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
        background-color: $secondary;
        -webkit-background-color: $primary;
        border-color: $primary;
        -webkit-border-color: $primary;

      }
      .custom-control {
        padding: 0;
        margin-left: 2rem;
      }
    }
    .opt {
      width: 175px;
      .sh{
        min-height: 20px;
      }
    }
    .opt-img {
      &.disabled {
        background-color: #e3e3e3;
      }
      width: 120px;
      border: 3px solid;
      padding: 20px;
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 45px;
      margin-bottom: 15px;

      img {
        width: 120px;
      }
    }
    
    .custom-control-label::before {
      transition: none;
    }
  }
  .button-section{
    button{
      min-width: 120px;
      width: auto;
      border-radius: 18px;
    }
  }
  .form-small-label {
    position: absolute;
    top: -10px;
    z-index: 1;
    left: 18px;
    background: #fff;
  }

  select:focus, button:focus {
    outline: none;
    box-shadow: none;
    border: 1px solid #c5c5c5
  }

  .fs-07{
    font-size: 0.8rem !important;
  }
  .fs-06{
    font-size: 0.6rem !important;
  }
  .fix-w-351{
    max-width: 351px;
  }
}



.network-status {
  position: fixed;
  z-index: 4;
  color: #fff;

  &.online {
    background: #509250;
  }

  &.offline {
    background: #d43a3a;
  }

  &.connecting {
    background: #ffc107;
    color: #000;
  }

  bottom: 30px;
  left: 5px;
  padding: 2px 5px;
  font-size: 10px;
  border-radius: 2px;
}

// Styling for the masthead
header.masthead {
  position: relative;
  height: 100vh;
  width: 100%;

  /*color: white;*/
  /*
    background: url('../img/bg-pattern.png'), $theme-secondary;
    background: url('../img/bg-pattern.png'), -webkit-linear-gradient(to left, $theme-secondary, $theme-tertiary);
    background: url('../img/bg-pattern.png'), linear-gradient(to left, $theme-secondary, $theme-tertiary);
    */
  .header-content {

    max-width: 500px;
    margin-bottom: 100px;

    text-align: center;

    h1 {
      font-size: 30px;
    }
  }

  .device-container {
    max-width: 375px;
    margin-right: auto;
    margin-left: auto;

    .screen img {
      border-radius: 0px;
    }
  }

  @media (min-width: 992px) {
    height: 100vh;
    min-height: 775px;
    padding-top: 0;
    padding-bottom: 0;

    .header-content {
      margin-bottom: 0;

      text-align: left;

      h1 {
        font-size: 50px;
      }
    }

    .device-container {
      max-width: 374px;
    }
  }
}

.device-mockup {
  min-height: 800px;
  position: relative;
  width: 100%;
  background-position: center top;
  background-repeat: no-repeat;
  background-image: url('../images/welcome-bg.jpg');

  .screen {
    top: 185px;
    left: 97px;
    position: absolute;
  }

  .screen-content {
    color: #E8E8E8;
    position: absolute;
    top: 400px;

    span {
      font-size: 22px;
    }

  }

}

.text-orange {
  color: $secondary;
}

.screen-layout {
  .layout-container {
    .card-header {
      padding: 1.15rem;
    }

    line-height: 1;
    padding: 0;

    .collapse-title {
      font-size: 12px;
      font-weight: 500;
    }

    .search {
      border-radius: 18px;

      span,
      input {
        background: #f6f6f6
      }

      input {
        border-radius: 0 18px 18px 0;
      }

      span {
        border-radius: 18px 0 0 18px;
      }

      &.active {
        span {
          border-radius: 18px 0 0 0;
        }
      }
    }

    .sorting-list,
    p {
      font-size: 10px;
      font-weight: 600;
    }

    .influencer:last-child {
      margin-bottom: 50px;

      .bd-top {
        border-bottom: 1px solid #E8E8E8;
      }
    }

    .user {
      /*margin-left: 12px;*/
      margin-top: 2px;

      .name {
        font-size: 11px;
        font-weight: 500;
        /*line-height: 14px;*/
        color: #11243D;
      }

      .status {
        font-size: 9px;
        font-weight: 400;
        /*line-height: 13px;*/
        color: #707A89
      }
    }
  }
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.messages-p {

  .text-decoration-none {
    color: #000;
  }

  ._tabs {
    .nav-tabs {
      border-bottom: 1px solid #f6f6f6;
    }

    .nav-link {
      background: #fff;
      color: #000;
      margin: 0;

      &:hover {
        border: 0;
      }

      &.active {
        background: #f6f6f6;
        border: 1px solid #f6f6f6;
      }
    }
  }

  .input-group.search {
    z-index: 1;

    &.active {
      .form-control {
        border-radius: 0 18px 0 0;
      }
    }
  }

  .datalist {
    background: #f6f6f6;
    list-style: none;
    padding-top: 15px;
    padding-bottom: 15px;
    position: absolute;
    width: 100%;
    top: 35px;
    margin-bottom: 0;
    z-index: 1;
    -webkit-box-shadow: 0 8px 6px -6px black;
    -moz-box-shadow: 0 8px 6px -6px black;
    box-shadow: 0 8px 6px -6px black;

    li {
      padding: 5px;
      border-bottom: 1px solid #f8f8f8;

      &:hover {
        background: #efeded;
      }
    }
  }

  .nm-container {
    .nm-text {
      font-size: 12px;
      font-weight: 700;
    }

    .msg {
      .campaign-title {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        margin-left: 12px;
        margin-right: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      ._body {
        width: 1px;
        ._icon {
          font-size: 12px;
          .file {
            width: 15px;
            height: 15px;
          }
        }
      }

      .unread {
        margin-left: 5px;
        display: flex;
        align-self: flex-start;
        padding-top: 9px;
      }

      .msg-text,
      .username {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        font-weight: 400;
        padding-left: 12px;
        color: #707A89;
        margin-right: 12px;

        &._influencer {
          font-size: 8px;
        }
      }

      .msg-time {
        color: #707A89;
        font-size: 8px;
        line-height: 13.21px;
        font-weight: 500;
        min-width: 65px;
      }
    }
  }

  .cancel-new-msg {
    position: fixed;
    bottom: 30px;
    right: 30px;
    display: none;

    &.btn-primary {
      z-index: 2;
    }
  }

  .new-msg-btn {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 1;
  }

  #create-msg {
    width: calc(100% - 315px);
  }

  .create-msg-bottom {
    bottom: 0px !important;
    overflow: auto;
  }

  .create-msg {
    height: 100%;
    bottom: -100%;
    /*padding-bottom: 50px;*/
    /*overflow: auto;*/
    width: inherit;
    transition: bottom 0.6s;
    background: #1C1C1C;
    color: #fff;
    position: fixed;
    opacity: 1;
    z-index: 3;

    .scrollable {
      height: 80%;
      width: 100%;
      position: absolute;
      overflow: auto;
    }

    .card,
    .card-header,
    .card-body {
      background: #1C1C1C;
      color: #fff;
    }

    ._title {
      font-size: 13px;
      font-weight: 500;
    }

    ._top {
      font-size: 13px;
      font-weight: 500;
      background: #1C1C1C;
      color: #fff;
    }

    ._search {
      background: #1C1C1C;

      .search span,
      .search input {
        background: #232323 !important;
        color: #fff !important;
      }
    }

    .adcampaigns {

      /*height: calc(81% / 2.6);*/
      /*overflow: auto;*/
      .user-avatar {
        padding-top: 0 !important;
      }

      ._header-title {
        padding: 1rem 0.5rem !important;
      }
    }

    .influencers,
    .adcampaigns {

      &.bd-bottom,
      .bd-bottom {
        border-color: #383838;
      }

      ._influencer:last-child,
      .adcampaign:last-child {
        ._header-title {
          border: 0;
        }
      }

      ._influencer,
      .adcampaign {
        .bd-top {
          border-color: #383838;
        }

        background: #1C1C1C;
        padding: 0 0.5rem 0 1rem;
      }

      .user-avatar {
        padding-top: 0.5rem;
      }

      ._header-title {
        padding: 0.75rem 0.5rem 0.5rem 0.5rem;
      }
    }

    /*.adcampaigns{
            .card{
                padding: 0;
                .card-body{
                    padding: 1rem 1rem 1rem 0;
                    margin-left: 2.2rem;
                }
            }
        }*/
  }
}

.agreements-p {
  ._title {
    font-size: 12px;
    font-weight: 500;
    line-height: 11.73px;
  }

  .agreement {
    .tt {
      color: #11243D;
      font-size: 10px;
      font-weight: 600;
      line-height: 15.76px;
    }

    .stt {
      color: #95A0AE;
      font-size: 12.25px;
      font-weight: 400;
      line-height: 14.94px;
    }

    .card-body {
      background: #F7F7F7;
      margin: 0rem 1.3rem;
      border-radius: 15px;

      .body-title {
        font-size: 11.97px;
        line-height: 11.74px;
        font-weight: 600;
        margin-bottom: 12px;
      }

      p {
        font-size: 10px;
        font-weight: 400;
        line-height: 13.73px;
        color: #5C5C5C;
      }

      a {
        line-height: 13.73px;
        font-size: 14px;
        font-weight: 500;
      }

      .small-text {
        font-size: 9px;
      }
    }
  }
}

.fancybox-image,
.fancybox-spaceball {
  background: oldlace;
}

.fancybox-slide--video .fancybox-content {
  height: auto !important;
}

.fancybox-slide--html {
  padding: 44px;
}

.fancybox-container {
  width: 100.2%;
}

.fancybox-content.quicktime-video {
  video {
    width: auto;
    max-height: calc(100vh - 88px);
    max-width: 100.3%;
  }

  background: #000;
  padding: 0;
  max-height: calc(100vh - 88px);
  /*max-width: 100%;*/
  line-height: 0;
  /*height: auto;*/
  /*width: auto;*/
  overflow: hidden;
}

/*fancybox-button fancybox-button--close*/
.fancybox-button.fancybox-close-small {
  position: fixed;
  color: #fff;
  padding: 10px;
  background: rgba(30, 30, 30, .6);
  width: 44px;
  height: 44px;
}

.environment-p {
  table {
    table-layout: fixed;
  }

  .password-inputs {
    margin-top: 150px
  }
}

.chat-p {
  .chat-header {
    .load-more-link {
      position: absolute;
      top: 61px;
      padding: 0;
      left: 0;
      right: 0;
      font-size: 12px;
    }
  }

  .chat {
    .card-body {
      height: 77vh;
      max-height: 77vh;
      padding: 0 1rem 65px 1rem;
      overflow: auto;
    }

    .card-footer {
      padding: 0;

      .progress-line {
        height: 14px;
        width: 100%;
        color: #fff;
        font-size: 11px;
        padding: 3px;
        background-color: #fff;
        background: linear-gradient(to right, $secondary 0% 0%, #fff 0% 100%);
      }
    }

    .response-box {
      padding: 1rem 0rem 0 1rem;

      textarea {
        border: 0;
        padding: 0;
        height: 16px;
        width: 80%;
        max-height: 145px;
        resize: none;
        overflow-y: auto;
        display: flex;

        &::-webkit-scrollbar {
          display: none;
        }

        -ms-overflow-style: none;
        scrollbar-width: none;

      }

      .icons {
        .icon {
          position: absolute;
          right: 8px;

          &.send-text {
            bottom: 9px;
          }

          &.media-attachment {
            right: 43px;
            bottom: 10px;
          }
        }

        position: relative;
      }
    }

    .msg {
      display: flex;
      padding-top: 10px;

      .img-msg-body {
        /*display: flex;*/
        max-width: 80%;
        width: auto;
        margin: 0 0.25rem;

        ._preview {
          object-fit: cover;
          max-width: 100px;
          max-height: 100px;
        }
      }


      .video-msg-body {
        max-width: 80%;
        position: relative;
        width: auto;
        margin: 0 0.25rem;

        .video-container {
          border: 1px solid #e3e3e3;
        }

        .btn-player {
          background: #ebebeb;
          padding: 33px;
        }

        &.video-thumbnail {
          .thumbnail-img {
            object-fit: cover;
          }

          .btn-player {
            border-radius: none;
            border: 0;
            position: absolute;
            top: 32px;
            left: 32px;
            width: 36px;
            height: 36px;
            padding: 2px;
            background: #ebebeb;
            border-radius: 17px;
          }
        }

        video {
          width: auto;
          max-width: 100%;
          min-width: 200px;
          height: 200px;
          max-height: 427px;
        }
      }

      .application-msg-body {
        position: relative;

        ._file {
          padding: 5px 10px;
          font-weight: 500;
          bottom: 20px;
          border-radius: 0 0 10px 10px;
          color: #000;
          margin: 0.25rem;
          background: #e3e3e3;

          .placeholder {
            width: 34px;
            height: 34px;
            margin-right: 10px;
          }

          ._name {
            width: 85%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }

      .msg-time {
        font-size: 8.17px;
        line-height: 8.01px;
        font-weight: 500;
        color: #B9B9B9;
      }

      .msg-text {
        line-height: 14.63px;
        font-weight: 400;
        font-size: 12px;
        padding: 0.5rem;
        margin: 0 0.25rem 0.25rem 0.25rem;
      }

      .btn-player,
      ._preview {
        border: 1px solid #e3e3e3;
      }

      &.mm {
        justify-content: flex-end;

        .msg-time {
          text-align: left;
          margin: 0.25rem 0 0.25rem 0.25rem;
        }

        .video-thumbnail img,
        .btn-player,
        ._preview,
        .msg-text,
        .application-msg-body ._file {
          border-radius: 10px 0px 10px 10px;
        }

        .msg-body {
          max-width: 80%;
          min-width: 16%;
        }

        .msg-text {
          background: #07BDE4;
          color: #fff;
        }
      }

      &.om {

        .video-thumbnail img,
        .btn-player,
        ._preview,
        .msg-text,
        .application-msg-body ._file {
          border-radius: 0px 10px 10px 10px;
        }

        .msg-time {
          text-align: right;
          margin: 0.25rem 0.25rem 0.25rem 0;
        }

        .msg-text {
          background: #F4F4F4;
        }
      }
    }
  }
}

.campaign-card {
  margin-bottom: 10px;

  .card-body {
    background: #E6E6E6;
    color: #000;
    border-radius: 4px;

    li {
      font-size: .8rem
    }
  }

  .card-body:hover {
    cursor: pointer
  }
}

.main-content .card,
.card-footer {
  background: #fff;
}

/* Mobile Header Design */
.mobile-header {
  position: fixed;
  width: 100%;
  z-index: 5;
  top: 0;
  left: 0;
  height: 100%;
  max-height: 50px;
  clear: both;
  background: $primary;

  .h-user-avatar {
    display: flex;

    img {
      width: 34px;
      height: 34px;
    }
  }

  .h-text {
    span {
      font-size: 16px;
      color: #fff;
      font-weight: bold;
    }
  }

  .h-back-button {
    display: none;
  }
}

.fixed-center {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
}

.bottom-20 {
  bottom: 20px;
}

.bottom-30 {
  bottom: 30px;
}

.bottom-60 {
  bottom: 60px;
}

.btn-center {
  margin-left: 115px;
}

.budget-influencer-payment-p, .onboarding-p {
  input[type='range'] {
    background: linear-gradient(to right, $primary 0%, $primary 0%, #ddd 0%, #ddd 100%);
    border-radius: 8px;
    height: 2px;
    outline: none;
    transition: background 450ms ease-in;
    -webkit-appearance: none;
  }

  input[type='range']::-webkit-slider-thumb {
    background-color: #fff;
    border: 2px solid #e3e3e3;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    -webkit-appearance: none;
  }
}
.budget-influencer-payment-p,
.budget-influencer-payment-section {
  h3 {
    font-size: 20px;
    font-weight: 400;
    text-align: center;
  }

  h6 {
    font-size: 14px;
    font-weight: 600;
  }

  .text-muted {
    font-size: 10px;
    margin-right: 18px;
  }

  .range-container {
    label {
      font-size: 10px;
      font-weight: 600;
    }
  }

  .disclaimer {
    margin-top: 40px;
    font-size: 9px;
  }

  .payment-button-section {
    position: fixed;
    bottom: 50px;
    left: 50%;
  }
}

.campaign-p,
.create-campaign-p {

  // Editor part
  .editor {
    display: flex;
    flex-direction: column-reverse;
  }

  .ql-editor {
    max-height: 250px;
  }

  .ql-toolbar.ql-snow {
    padding: 0px;
  }

  .ql-toolbar.ql-snow+.ql-container.ql-snow {
    border-top: 1px solid;
    border-color: #C5C5C5;
    border-radius: 5px 5px 0 0;
  }

  .ql-toolbar.ql-snow {
    border: 0;
    border-color: #C5C5C5;
    background: #D0D0D0;
    border-radius: 0 0 5px 5px;
  }

  label {
    text-transform: capitalize;
    font-size: 12px;
    font-weight: bold;
  }

  .male-ratio-box {
    width: 20px;
    display: inline-block;
    height: 20px;
    background: green;
  }

  .form-small-label {
    position: absolute;
    top: -11px;
    background: #fff;
    left: 26px;
    padding: 0px 4px 0 4px;
  }

  /*Multi range */
  [slider] {
    position: relative;
    height: 4px;
    border-radius: 2px;
    text-align: left;
    margin: 10px 0 10px 0;
  }

  [slider]>div {
    position: absolute;
    left: 0px;
    right: 0px;
    height: 2px;
  }

  [slider]>div>[inverse-left] {
    position: absolute;
    left: 0;
    height: 2px;
    border-radius: 2px;
    background-color: #DEDEDE;
    /*margin: 0 7px;*/
  }

  [slider]>div>[inverse-right] {
    position: absolute;
    right: 0;
    height: 2px;
    border-radius: 2px;
    background-color: #DEDEDE;
    /*margin: 0 7px;*/
  }

  [slider]>div>[range] {
    position: absolute;
    left: 0;
    height: 2px;
    border-radius: 2px;
    background-color: $secondary;
  }

  .noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                      supported by Chrome, Edge, Opera and Firefox */
  }

  .left-thumb {
    margin-left: 0px;
  }

  .right-thumb {
    margin-left: -6px;
  }

  [slider]>input[type=range] {
    position: absolute;
    pointer-events: all;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    z-index: 3;
    height: 14px;
    background: transparent;
    top: -6px;
    width: 100%;
  }

  div[slider]>input[type=range]::-ms-thumb {
    pointer-events: all;
    width: 14px;
    height: 28px;
    border-radius: 0px;
    border: 0 none;
    background: $secondary;
    appearance: none;
    border-radius: 15px;
  }

  div[slider]>input[type=range]::-moz-range-thumb {
    pointer-events: all;
    width: 14px;
    height: 28px;
    border-radius: 0px;
    border: 0 none;
    background: $secondary;
    appearance: none;
    border-radius: 15px;
  }

  div[slider]>input[type=range]::-webkit-slider-thumb {
    pointer-events: all;
    width: 14px;
    height: 28px;
    border-radius: 15px;
    border: 0 none;
    background: $secondary;
    appearance: none;
  }

  /* Male female ratio input  */
  .gender-range,
  ._slider {
    input[type='range'] {
      background: #DEDEDE;
      border-radius: 8px;
      height: 2px;
      outline: none;
      transition: background 450ms ease-in;
      appearance: none;
      -webkit-appearance: none;
      -ms-appearance: none;
      -moz-appearance: none;
      margin: 10px 0 10px 0;
    }

    input[type=range]::-webkit-slider-thumb {
      /*pointer-events: all;*/
      width: 14px;
      height: 28px;
      border-radius: 15px;
      border: 0 none;
      background: $secondary;
      appearance: none;
    }

    input[type=range]::-ms-thumb {
      /*pointer-events: all;*/
      width: 14px;
      height: 28px;
      border-radius: 15px;
      border: 0 none;
      background: $secondary;
      appearance: none;
    }

    input[type=range]::-moz-range-thumb {
      /*pointer-events: all;*/
      width: 14px;
      height: 28px;
      border-radius: 15px;
      border: 0 none;
      background: $secondary;
      appearance: none;
    }

    .labels div {
      font-size: 12px;
      color: #aaa;
    }

  }

  input[type='range']::-webkit-slider-runnable-track {
    /*height: 10px;*/
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    /*color: #13bba4;*/
    /*margin-top: -1px;*/
  }

  .audience-gender {
    .d-inline-block {
      margin-top: -2px;
    }

    label.form-check-label {
      color: #aaa;
      font-size: 10px;
      text-transform: inherit;
    }

    .form-check-input {
      margin-top: 0.34rem;
    }
  }


  .p-card>.card-body {
    background: #fff
  }

  .card-title,
  .card-header {
    font-weight: 600
  }

  .btn-next {
    width: 18rem;
    font-weight: bold;
    &:disabled {
      background: #BDBDBD;
    }
  }

  .card-body.selected {
    background: $secondary;
    color: $primary;
  }

  /* Chrome and Others */
  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .hide-disabled {
    display: none;
  }

  .card-body {
    label {
      color: #11243D
    }
  }

  .campaign-brief-file-upload-section {
    position: relative;

    label.campaign-brief-label {
      position: relative;
      width: 80%;
      height: 71px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px dashed #c4c4c4;
      border-radius: 6px;
      font-weight: 500;
      color: #c4c4c4;
      font-size: 12px;
      margin-left: auto;
      margin-right: auto;

      span {
        color: $secondary;
        padding-right: 3px;
        font-weight: 600;
      }

      .plus-icon-orange {
        position: absolute;
        left: -17px;
        border-radius: 60%;
      }
    }

  }

  .progress-bar-section {
    .progressbar {
      #file_name {
        color: #B3B3B3;
        font-size: 12px;
        font-weight: 500;
      }

      .progress-line {
        height: 4px;
        background-color: #fff;
        background: linear-gradient(to right, $secondary 0% 0%);
      }
    }
  }

  .budget-influencer-payment-section {
    h3 {
      font-size: 20px;
      font-weight: 400;
      text-align: center;
    }

    h6 {
      font-size: 14px;
      font-weight: 600;
    }

    .text-muted {
      font-size: 10px;
      margin-right: 18px;
    }

    .range-container {
      label {
        font-size: 10px;
        font-weight: 600;
      }
    }

    input[type='range'] {
      background: linear-gradient(to right, $secondary 0%, $secondary 0%, #e3e3e3 0%, #e3e3e3 100%);
      border-radius: 8px;
      height: 2px;
      outline: none;
      transition: background 450ms ease-in;
      -webkit-appearance: none;
    }

    input[type='range']::-webkit-slider-thumb {
      background-color: #fff;
      border: 2px solid #e3e3e3;
      height: 25px;
      width: 25px;
      border-radius: 50%;
      -webkit-appearance: none;
    }

    .payment-button-section {
      position: fixed;
      bottom: 50px;
      left: 50%;
    }
  }
}


.daterangepicker {
  z-index: 4;

  td.active,
  td.active:hover {
    background: $primary;
  }

  color: black;

  .drp-selected {
    padding-right: 4px;
  }
}

// Publishing date range input
.daterangepicker.show-calendar .drp-buttons {
  display: flex;
  align-items: center;
}

.my-calendar-p {
  .chevron-right-icon {
    min-width: 10px;
  }

  .empty-list {
    padding-top: 25px;
  }

  .layout-container {
    .campaign-header-section {
      padding: 0.75rem 1.5rem;
    }

    ._collapsable {
      .card-body {
        margin-left: 56px;
      }
    }

    .collapse-title {
      font-size: 14px;
      font-weight: 600;
    }

    .status {
      font-size: 10.75px;
      text-decoration: underline;
    }

    .drafts {
      padding: 1rem;

      .accordion:nth-child(1) {
        .card-header {
          padding-top: 1rem;

          .bd-top {
            border: 0;
          }
        }
      }
    }

    .submitted-draft {
      .card-header {
        padding: 0rem 0 0 1rem;

        .pading {
          padding: .5rem;
        }
      }
    }

    .files {
      .file {
        width: 30px;
        height: 30px;
        background: url('~/images/file-placeholder.svg') no-repeat;
        display: inline-block;
        float: left;
        margin-right: 5px;
      }

      .video-file {
        background: url('~/images/play-button.svg') no-repeat;
        background-size: contain;
      }

      .image-gallery {
        background: url('~/images/image-gallery.svg') no-repeat;
        background-size: contain;
      }

      .carousel-album {
        background: url('~/images/carousel-album.svg') no-repeat;
        background-size: contain;
      }
    }
  }
}

.empty-list {
  color: #8D8D8D;
  text-align: center;
  padding-top: 100px;
}

/*Lite Picker*/
.litepicker {
  font-family: $font-family-sans-serif;
  width: 100% !important;

  .container__months {
    box-shadow: none;

    .month-item-header div>.month-item-year {
      padding-right: 20px;
    }
  }

  .container__days>div,
  .month-item-weekdays-row>div {
    width: 14.28%;
    margin: 10px 0px;
    padding: 5px;
  }

  .container__main,
  .container__months,
  .month-item {
    width: 100% !important;
  }

  select.month-item-name::-ms-expand {
    display: none;
  }

  select.month-item-name {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    font-weight: 600;
  }

  select.month-item-year {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('~/images/select-arrow.svg');
    background-repeat: no-repeat;
    background-position-x: 90%;
    background-position-y: 11px;
  }

  select {
    /*background: #fff;*/
    background: transparent;
    border: 0;
    font-size: 16px;
    line-height: 29px;

    &:focus {
      outline: none;
    }
  }


  .container__days {
    font-size: 11px;
    font-weight: 900;
    font-family: 'Roboto';


    .day-item {
      margin-top: 0;
      margin-bottom: 0;

      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 32px;
      position: relative;

      &:hover{
        box-shadow: none;
        color: inherit;
      }

      &.is-start-date,
      &.is-end-date {
        background: #fff;
        color: #000;
      }

      &.is-highlighted {
        background: #fff;
      }

      &.is-next-month-day,
      &.is-previous-month-day {
        color: #9e9e9e;
      }

      &.is-highlighted {
        color: #fff;
      }

      &.is-publishing-date {
        color: #fff;
        .box-center .c-highlight {
          background-color: #38a169;
        }
      }
            
      .p-absolute {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
      .highlights {
        z-index: 0;
      }

      &.is-start-date {
        .range-part {
          justify-content: flex-end;
          .day-layer {
            background: $is-in-range;
          }
        }
        .box-center .c-highlight {
          background-color: $secondary;
        }
      }
      
      &.is-in-range {
        background: #fff;
        .range-part > .day-layer {
          width: 100%;
          background: $is-in-range;
        }
        .day-0 {
          .range-part > .day-layer {
            background: linear-gradient(90deg, $secondary1 38.33%, rgba(209, 160, 132, 0.001) 100%);
          }
        }
        .day-1 {
          .range-part > .day-layer {
            background: linear-gradient(-90deg, $secondary1 8.33%, rgba(209, 160, 132, 0.001) 100%);
          }
        }
      }

      &.is-end-date {
        .range-part {
          justify-content: flex-start;
          .day-layer {
            background: $is-end-date;
          }
        }
        .box-center .c-highlight {
          background-color: $secondary;
        }
      }

      &.is-start-date.is-end-date {
        .range-part .day-layer {
          background: #fff;
        }
        .box-center .c-highlight {
          background-color: $secondary;
        }
      }

      &.is-previous-month-day, &.is-next-month-day {
        &.is-start-date,
        &.is-end-date,
        &.is-in-range {
          .range-part .day-layer {
            opacity: 0.5;
          }
        }
        
        &.is-start-date,
        &.is-end-date {
          .box-center .c-highlight {
            opacity: 0.2;
          }
        }
      }


      .range-part {
        width: 100%;
        height: 32px;
        display: flex;
        align-items: center;
        .day-layer {
          height: 28px;
          width: 50%;
        }
      }
      
      .box-center {
        .c-highlight {
          width: 28px;
          height: 28px;
          border-radius: 50%;
        }
      }

      .centerr {
        z-index: 1;
        position: relative;
      }
    }
  }

  .container__months {
    .month-item-weekdays-row>div {
      font-size: 10px;
    }

    .month-item-header {
      justify-content: flex-start;

      div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
  }

  .button-previous-month,
  .button-next-month {
    display: none;
  }
}

._38x38 {
  width: 38px !important;
  height: 38px !important;
}

._38w {
  width: 38px !important;
}

.cancelBtn {
  color: #000;
  background: #EFEFEF
}

label.error {
  color: red !important
}

.error.valid {
  display: none !important;
}

.toggle-control {
  display: block;
  position: relative;
  padding-left: 55px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  height: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.toggle-control-simple {
    padding-left: 46px;

    .control {
      height: 18px;
      width: 38px;
      top: 7px;
    }
  }
}

.toggle-control input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.toggle-control input:checked~.control {
  background-color: $secondary;
}

.toggle-control input:checked~.control:after {
  left: 30px;
}

.toggle-control .control {
  position: absolute;
  top: 0;
  left: 0;
  height: 27px;
  width: 55px;
  border-radius: 25px;
  background-color: darkgray;
  transition: background-color 0.15s ease-in;
}

.toggle-control .control:before {
  content: "";
  background: url('~/images/tick.svg') no-repeat;
  height: 12px;
  width: 17px;
  position: absolute;
  left: 7px;
  top: 6px;
}

.toggle-control .control:after {
  content: "";
  position: absolute;
  left: 3px;
  top: 2.5px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background: white;
  transition: left 0.15s ease-in;
}

.toggle-control-simple .control:before {
  background: none;
}

.toggle-control-simple input:checked~.control:after {
  left: 23px !important;
}

.toggle-control-simple .control:after {
  width: 12.5px;
  height: 12.5px
}

/* reset new password page */
.reset-password-p {
  .card .card-header {
    margin-top: 39px;
  }
}

.register-p {
  .card .card-header {
    margin-top: 67px;
  }
}

/* Forgot password page */
.forgot-password-p {
  .card-header img {
    margin-bottom: 10px;
  }

  .font-size {
    font-size: 16px;
    font-weight: normal;
  }

  .card-header {
    margin-bottom: 1rem;
  }

  .btn-primary {
    width: 111px;
  }
}

.sign-in-p {
  .l-side {
    background: url('../images/welcome-bg.jpg') no-repeat;
    background-size: cover;
  }

  .dna-logo {
    vertical-align: middle;
    border: 2px solid #fff;
    border-radius: 22px;
  }

  .invalid-flash {
    color: $red;
    font-size: 13px;
  }
}

.divider {
  border-bottom: 1px solid #545454;
}

/* campaign published page */
.campaign-published-p {
  .card {
    .card-header {
      &.completed {
        background-color: #3BB547;
      }

      &.approved {
        background-color: #FFC148;
      }

      &.danger {
        background-color: $red;
      }

      color: #fff;

      .status>h4 {
        font-size: 18px;
        font-weight: 700
      }

      .sub-status {
        line-height: 15px;
        width: 76%;
      }
    }

    .card-body {
      .col-6 {
        margin-bottom: 0.5rem;
      }

      .order-details-section {
        .date {
          font-size: 12px;
          color: #4A4A4A;
        }

        .details,
        {
        .title {
          font-size: 13px;
          color: #4A4A4A;
          font-weight: 700;
        }

        .title-text {
          font-size: 13px;
          font-weight: 700;
        }
      }

      .payment-amount {
        .title {
          font-weight: 700;
          font-size: 13px;
          color: #4A4A4A;
        }

        .title-text {
          font-weight: 700;
          font-size: 13px;
        }
      }
    }
  }
}
}

/* Sidebar Menu */
.sidebar-menu {
  background: $primary;
  font-size: 14px;
  width: 315px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;

  .nav-item a {
    font-size: 12px;
    color: $nav-link;
  }

  .nav-item.active a {
    color: $nav-link-active;
    font-weight: 700;
  }

  .user-panel {

    /*padding: 10px 0px 0px 0px;*/
    .image {
      position: relative;
      width: fit-content;

      img {
        width: 100%;
        max-width: 38px;
        height: auto;
      }
    }
  }

  .logout-panel {
    position: absolute;
    bottom: 0;
    left: 0;

    a {
      font-size: 12px;
      margin-left: -63px;
    }
  }

  .menu {
    width: 50%;
    margin: 20px auto 0px 26%;
    color: #fff;

    label {
      font-size: 14.82px;
      font-weight: 700;
      line-height: 18.07px;
    }

    margin: 20px auto 0px 26%;

    .menu-items {
      margin-left: -26px;
    }
  }

  .actions .btn {
    border-radius: 0px;
  }

}

.dot {
  height: 8px;
  width: 8px;
  background-color: $secondary;
  border-radius: 50%;
  display: inline-block;
}

.status-dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  background-color: #509250;
  position: absolute;
  bottom: 0;
  right: 0;

  &.offline {
    background-color: white;
    border: 2px solid #C6C5C6;
  }

  &.online {
    background-color: #509250;
  }

  &.connecting {
    background-color: #ffc107;
  }
}

.btn-orange {
  background: $secondary;
  color: #fff;
}

/* Spinner remove */
input.no-spinner::-webkit-outer-spin-button,
input.no-spinner::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.no-spinner {
  -moz-appearance: textfield;
}

@import 'icons';
@import 'buttons';

/* Content area */
.main-content {
  background: #fff;
  margin-left: 315px;
  width: calc(100% - 315px);
  min-height: 100vh;
}

.img-circle {
  border-radius: 50%;
}

.tick {
  width: 25px;
  height: 25px;
  display: inline-block;
  background-size: contain !important;
}

.card {
  margin-top: 0px;
  border-radius: 0px;

  .card-header {
    border-radius: 0px;
    background: #fff
  }

  .user-avatar {
    img {
      width: 34px;
      height: 34px;
      font-size: 8px;
    }
  }

}

/* Campaign details */
.title {
  color: #A7A7A7;
  font-size: 10px;
  font-weight: 400;
}

.title-text {
  font-size: 10px;
  font-weight: 500;
}

.section-header {
  font-weight: 600;
  font-size: 14px;
}

.custom-checkbox {
  .custom-control-label::before {
    border-radius: 3px;
  }
}

/* Proposals index page */
.proposals-p {
  .proposals-container {
    font-size: 12px;

    a {
      color: #000;
    }
  }
}

/* Analytics Report page */
.influencer-analytics-report-p {
  .main-card {
    line-height: 1;

    .user-avatar-div {

      /* width: 77px;
            height: 77px;
            padding: 2px;
            background: linear-gradient(to right, $secondary, #441D06);
            border-radius: 50%;*/
      img {
        width: 73px;
        height: 73px;
      }
    }

    .name {
      font-size: 17px;
      font-weight: 600;
      line-height: 25px;
      margin-bottom: 0;
    }

    .username {
      font-size: 10px;
      font-weight: 500;
      color: $secondary;
    }

    .stars {
      margin-top: 13px;
    }

    .insights {
      height: 76px;

      .bd-left {
        height: 75%;
      }

      .number {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 10px;
      }

      small {
        font-size: 9px;
        font-weight: 600;
      }
    }

    .switch-button {
      margin-top: 10px;

      .label {
        font-size: 11px;
        font-weight: 500;
      }
    }

    ._posts {
      margin: 10px 0 50px 0;

      a {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          margin: 0;
          /*padding: 1px;*/
        }
      }

      .file {
        border: 1px solid #e3e3e3;
        padding-top: 33.3%;
        max-width: 33.3%;
        position: relative;

        .dna-play-icon,
        .dna-carousel-icon {
          position: absolute;
          top: 5px;
          right: 5px;
          z-index: 2;
        }

        a {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
          z-index: 1;

          img {
            object-fit: cover;
          }
        }
      }
    }

    .card-footer {
      position: fixed;
      z-index: 1;
      /*display: flex;*/
      /*justify-content: center;*/
      bottom: 0;
      left: 0;
      margin-left: 315px;
      width: 100%;
      padding: 0;

      .accept-reject-proposal-area {
        width: calc(100% - 315px);
        display: flex;
        justify-content: center;

        button {
          font-size: 12px;
          font-weight: 600;
          height: 50px;
          border-radius: 0;
          width: 250px;
          border: 1px solid #e3e3e3;
          float: left;
        }
      }

      .btn-orange {

        &:focus,
        &:active {
          color: #fff;
        }
      }
    }
  }
}

.cursor-hand {
  cursor: pointer;
}

/* Proposals offers screens layout */
.proposals-screen-layout {
  .layout-container {
    line-height: 1;
    padding: 0;

    .collapse-title {
      font-size: 12px;
      font-weight: 500;
      color: #000;
    }

    .search {
      border-radius: 18px;

      span,
      input {
        background: #f6f6f6
      }

      input {
        border-radius: 0 18px 18px 0;
      }

      span {
        border-radius: 18px 0 0 18px;
      }
    }

    .offer {
      .card-header {
        padding: 1rem;
      }

      .sorting-list,
      p {
        font-size: 10px;
        font-weight: 600;
      }

      .list {
        .influencer:last-child {
          margin-bottom: 50px;

          .bd-top {
            border-bottom: 1px solid #E8E8E8;
          }
        }

        .card-header {
          .user {
            margin-left: 12px;
            margin-top: 2px;

            .name {
              font-size: 11px;
              font-weight: 500;
              /*line-height: 14px;*/
              color: #11243D;
            }

            .status {
              font-size: 9px;
              font-weight: 400;
              /*line-height: 13px;*/
              color: #707A89
            }
          }
        }

        .card-body {
          margin-left: 56px;
          margin-right: 17px;

          .file {
            width: 40px;
            height: 40px;
            background: #C5C5C5;
            float: left;
            margin-right: 5px;
          }
        }
      }
    }
  }
}

.payment-details-p,
.campaign-analysis-p {
  ._title {
    padding: 1rem 0rem;
    font-size: 18px;
    font-weight: 700;
  }

  ._header {
    .card-body {
      background: #F7F7F7;
      margin: 0rem 1.3rem;
      border-radius: 15px;

      .body-title {
        font-size: 17px;
        font-weight: 600;
      }

      .small-text {
        font-size: 9px;
      }
    }

    .card-footer {
      padding: 2.6rem;
      padding-top: 1.8rem;

      .title {
        font-size: 12px;
        margin-bottom: 12px;
      }

      .money {
        font-size: 50px;
        font-weight: 500;
      }
    }
  }

  .breakdown {
    .breakdown-body {
      .card-body {
        .payment {
          margin-bottom: 14px;
        }

        .payment-total {
          margin-top: 34px;
        }

        padding: 2.6rem;
        padding-bottom: 0;

        .title {
          margin-bottom: 10px;
        }

        .flex-grow-1 {
          margin-bottom: 13px;
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
  }

  .campaign-analysis {
    ._header {
      .card-body {
        .small-text {
          font-size: 11px;
        }
      }
    }

    ._body {
      .box {
        float: left;
        text-align: left;
        width: 145px;
        height: 145px;
        background: #F7F7F7;
        margin-right: 12px;
        margin-top: 12px;
        border-radius: 15px;
        display: flex;
        padding-left: 20px;
        flex-direction: column;
        justify-content: center;

        ._title {
          padding: .3rem 0rem;
          font-size: 10px;
          font-weight: 400;
        }

        h4 {
          font-size: 29px;
          font-weight: 600;
        }

        small {
          font-size: 8px;
          font-weight: 400;
        }
      }
    }
  }
}

#bottom-screen {
  position: fixed;
  width: calc(100% - 315px);
  height: 100vh;
  background: #fff;
  bottom: -100vh;
  transition: bottom 0.6s;
  opacity: 1;
  left: 315px;
  z-index: 3;

  [aria-hidden] {
    font-size: 24px;
    font-weight: 500;
    color: #99a1a9;
  }
}

.campaign-analysis-p {
  #bottom-screen {
    .heading-section {
      .card-body {
        padding-bottom: 0;
      }
    }

    .content-section {
      padding: 1.4rem 0;

      .card-title {
        font-weight: 600;
        font-size: 12px;
        padding-left: 1.25rem;
      }

      .card-body {
        padding-left: 1.25rem;
        padding-top: 0;
      }

      .influencers-list {
        .username {
          font-size: 14px;
        }

        .status {
          font-size: 12px;
        }

        .followers {
          font-size: 22px;
          font-weight: 600;
        }
      }
    }
  }

  .overall-reach {

    h4 {
      font-size: 29px;
      font-weight: 600;
    }

    .influencers-list {
      .influencer:last-child {
        .bd-top {
          border-bottom: 1px solid #E8E8E8;
        }
      }

      .card-header {
        .btn-link {
          font-size: 14px;
          font-weight: 500;
          line-height: 14px;
          color: #11243D;
          padding: 0 0 0 0.375rem;
        }

        .status {
          font-size: 9px;
          font-weight: 400;
          line-height: 13px;
          color: #707A89
        }

        .followers {
          font-size: 29px;
          font-weight: 600;
        }
      }

      .card-body {
        margin-left: 56px;
        margin-right: 17px;

        .file {
          width: 40px;
          height: 40px;
          background: #C5C5C5;
          float: left;
          margin-right: 5px;
        }
      }
    }
  }
}

.nda-p {

  .info {
    .username {
      font-size: 10.75px;
      font-weight: 500;
      margin-bottom: 7px;
    }

    .campaign-title {
      font-size: 12px;
      font-weight: 600;
      margin-bottom: 8px;
    }
  }

  .layout-container {
    .username {
      font-size: 14px;
      font-weight: 600;
    }
  }

  .para,
  .nda-table {

    p,
    table tr td {
      font-size: 10px;
      line-height: 21px;
      font-weight: 400;
      text-align: justify;
    }

    table tr th {
      font-size: 10px;
      line-height: 21px;
      font-weight: 400;
    }

    .highlight-text {
      background: #fff2af;
      color: #000;
    }

    h6 {
      font-size: 11px;
      font-weight: 600;
    }

    ul {
      padding-left: 8px;

      &.inner {
        padding-left: 16px;
        margin-bottom: 0;
      }
    }
  }

  ol:not(.level-2, .level-3) {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
  }

  ol:not(.level-2, .level-3)>li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
  }

  ol:not(.level-2, .level-3)>li:before {
    content: counters(item, ".");
    display: table-cell;
    padding-right: 0.6em;
    font-size: 12px;
    font-weight: 300;
  }

  li ol:not(.level-2, .level-3)>li {
    margin: 0;
  }

  li ol:not(.level-2, .level-3)>li:before {
    content: counters(item, ".") " ";
    font-size: 12px;
  }

  ol>li {
    margin: 7px 0;
    font-size: 10px;
    line-height: 21px;
    font-weight: 400;
    text-align: justify;
  }

  dl dt {
    font-size: 12px;
    margin-bottom: 10px;
  }

  dl dd {
    padding-left: 0px;
  }

  ol:not(.level-0)>li,
  .justify {
    text-align: justify;
    word-spacing: -1px;
  }

  ol.level-2,
  ol.level-3 {
    margin: 7px 0;
  }

  ol.level-2>li::marker {
    content: "(" counter(list-item, lower-alpha) ") ";
  }

  ol.level-3>li::marker {
    content: "(" counter(list-item, lower-roman) ") ";
  }
}

.influencers-list {
  .influencer:last-child {
    .bd-top {
      border-bottom: 1px solid #E8E8E8;
    }
  }

  .card-header {
    .username {
      font-size: 11px;
      font-weight: 500;
      line-height: 14px;
      color: #11243D;
      padding-left: 0.375rem;
    }

    .status {
      font-size: 9px;
      font-weight: 400;
      line-height: 13px;
      color: #707A89;
      padding-left: 0.375rem;
    }
  }

  .card-body {
    margin-left: 56px;
    margin-right: 17px;

    .file {
      width: 40px;
      height: 40px;
      background: #C5C5C5;
      float: left;
      margin-right: 5px;
    }
  }
}

.payment-p {

  .header-icon {
    min-width: 8px;
  }

  .layout-container {
    .collapse-title {
      font-size: 14px;
    }
  }

  .money {
    font-size: 14px;
  }

  .payment-history-campaign {
    ._title {
      font-size: 14px;
      padding: 1px;
      font-weight: 600;
    }

    .payment-influencer {
      font-size: 12px;

      .flex-grow-1,
      .amount {
        min-height: 29px;
      }

      a {
        color: #000
      }
    }
  }

  .layout-card-1 {
    .card-header {
      padding: 1rem;
    }

    .list {
      .card-header {
        .user {
          .name {
            font-weight: 600;
            font-size: 14px;
          }

          .status {
            font-size: 9px;
          }
        }
      }
    }
  }

}


/* Proposals influencers list page */
.proposal-influencers-list-p {
  .avatar {
    width: 34px;
    height: 34px;
    border-radius: 50%;
  }

  .influencers-container {
    padding: 5px 0 0 0;

    .search {
      border-radius: 18px;

      span,
      input {
        background: #f6f6f6
      }

      input {
        border-radius: 0 18px 18px 0;
      }

      span {
        border-radius: 18px 0 0 18px;
      }
    }

    .sorting-list {
      font-size: 10px;
      font-weight: 600;
      width: auto;
    }

    .influencers-list {
      .influencer:last-child {
        .bd-top {
          border-bottom: 1px solid #E8E8E8;
        }
      }

      .card-header {
        .btn-link {
          font-size: 11px;
          font-weight: 500;
          line-height: 14px;
          color: #11243D;
          padding: 0 0 0 0.375rem;
        }

        .status {
          font-size: 9px;
          font-weight: 400;
          line-height: 13px;
          color: #707A89
        }
      }

      .card-body {
        margin-left: 56px;
        margin-right: 17px;

        .file {
          width: 40px;
          height: 40px;
          background: #C5C5C5;
          float: left;
          margin-right: 5px;
        }
      }
    }
  }
}

.bd-top {
  border-top: 1px solid #E8E8E8;

  &.dashed {
    border-top: 1px dashed #E8E8E8;
  }
}

.bd-bottom {
  border-bottom: 1px solid #E8E8E8;

  &.dashed {
    border-bottom: 1px dashed #E8E8E8;
  }
}

.bd-left {
  border-left: 1px solid #E8E8E8;

  &.dashed {
    border-left: 1px dashed #E8E8E8;
  }
}

.bd-right {
  border-right: 1px solid #E8E8E8;

  &.dashed {
    border-right: 1px dashed #E8E8E8;
  }
}

.pt-75 {
  padding-top: .75rem;
}

.pl-75 {
  padding-left: .75rem;
}

/* Campaign details page */
.campaign-details-p {
  .campaign-description .title-text * {
    font-size: 10px;
  }

  .disclaimer {
    font-size: 9px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .options-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100vw;
    height: 100vh;
    background-color: #000;
    opacity: .5;
  }

  .loader-backdrop {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.91;
    background: $body-bg-black;
    z-index: 2;
  }

  .loader {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 3;
    position: absolute;
  }

  .options-modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 4;
    width: 100vw;
    height: 100vh;

    display: flex;
    align-items: center;

    .spinner-border {
      border: 0.05em solid #f8f9fa;
      border-right-color: transparent;
    }

    ._dialog {
      background-color: $body-bg-black;
      color: #fff;
      max-width: 638px;
      width: 93%;
      margin: 1.75rem auto;
      height: auto;
      border-radius: 8px;

      ._content {
        display: flex;
        flex-direction: column;
        min-height: 352px;
        position: relative;

        .close-btn {
          position: absolute;
          right: 8px;
        }

        ._slide-no {
          font-size: 14px;
          margin-top: 12px;
          font-weight: 600;
        }

        ._header {
          ._h-icon {
            margin-top: 14px;

            .options-icon {
              background: url('~/images/options-icon.svg') no-repeat;
              background-size: contain;

              &.confirmation {
                background: url('~/images/check-mark.svg') no-repeat;
                background-size: contain;
              }

              &.error {
                background: url('~/images/cross-icon.svg') no-repeat;
                background-size: contain;
              }

              width: 76px;
              height: 76px;
              display: inline-block;
            }
          }
        }

        ._body {
          min-height: 165px;
          height: auto;
          display: flex;
          justify-content: center;


          padding-bottom: 20px;

          .chevron-icon {
            background: url('~/images/options-right-arrow.svg') no-repeat;
            display: inline-block;
            width: 25px;
            height: 30px;


            &.left {
              transform: rotate(-180deg);
            }
          }

          .l-side {
            padding-bottom: 45px;
            min-width: 45px;
            justify-content: center;
          }

          .r-side {
            padding-bottom: 45px;
            min-width: 45px;
            justify-content: center;
          }

          .content {
            display: none;

            &.active {
              display: block;
              overflow: hidden;
            }

            ._title {
              font-size: 18px;
              font-weight: 500;
              padding-bottom: 20px;
            }

            ._description {
              font-size: 14px;
              font-weight: 400;
              color: #D1D1D1;

              input {
                width: 200px;
              }
            }
          }
        }

        ._footer {
          position: relative;
          display: flex;
          justify-content: center;
          height: 58px;

          button {
            margin-bottom: 20px;
          }

          .dots {
            position: relative;
            padding-top: 15px;
            display: flex;
            justify-content: center;
            width: 100%;

            .item {
              margin-right: 6px;
              background-color: #fff;
              background-clip: padding-box;
              opacity: .5;
              width: 7px;
              height: 7px;
              border-radius: 50%;

              &.active {
                opacity: 1;
                background-color: $secondary;
              }
            }
          }
        }
      }
    }
  }

  .campaign-details-section {
    .dropdown-menu {
      font-size: 9px;
      width: auto !important;
      min-width: 82px;
      z-index: 2;
    }

    .title {
      text-transform: capitalize;
    }

    .col-6 {
      margin-bottom: 1rem;
    }
  }

  .payment-btn-section {
    position: fixed;
    /*left: 50%;*/
    margin: auto 15%;
    bottom: 30px;
    text-align: center;

    .pay-now {
      width: 225px;
      background: #FFC148;
      color: #fff;
      font-weight: 600;
      height: 40px;
    }
  }

  .campaign-submitted-drafts-section {
    padding: 1rem;
    line-height: 1;

    .header-title {
      margin-left: 12px;
      margin-top: 2px;

      .collapse-title {
        font-size: 11px;
        font-weight: 500;
        line-height: 14px;
      }

      small {
        font-size: 9px;
      }
    }

    .card-body {
      margin-left: 56px;
      margin-right: 17px;

      .file {
        width: 30px;
        height: 30px;
        background: url('~/images/file-placeholder.svg') no-repeat;
        display: inline-block;
        float: left;
        margin-right: 5px;
      }

      .video-file {
        background: url('~/images/play-button.svg') no-repeat;
        background-size: contain;
      }

      .image-gallery {
        background: url('~/images/image-gallery.svg') no-repeat;
        background-size: contain;
      }

      .carousel-album {
        background: url('~/images/carousel-album.svg') no-repeat;
        background-size: contain;
      }
    }
  }

  .campaign-activity-section {
    .btn-sm {
      font-size: 0.5rem;
      // height: 22px;
      border-radius: 1px;
    }

    .activity-title {
      font-size: 10px;
      margin-top: 20px;
      text-align: center;
    }

    .activity-graph {
      font-size: 16px;
      margin-left: auto;
      margin-right: auto;
      width: 75px;
      height: 75px;
      border-radius: 50%;
      background: $secondary;
      color: $pfc;
    }
  }

  .matched-influencers-content {
    .tab-title {
      font-size: 14px;
      font-weight: 600;
      color: #A7A7A7;

      &.active {
        color: #000;
      }
    }

    .info {
      text-align: center;
      width: 100%;
      font-size: 12px;
    }


    .inf-section-row {
      .file {}
    }


    .matched-profile {

      img {
        width: 100%;
        height: 100%;
        margin: 0;
        object-fit: cover;
        border-radius: 10px;
      }

      .profile-info {
        text-align: center;
        font-weight: 600;
        display: flex;
        flex-direction: column;
        background: #000;
        color: #fff;
        opacity: 0.6;
        position: absolute;
        bottom: 0;
        width: 100%;
        border-radius: 0 0 10px 10px;
      }
    }

    .loadmore-link {
      button {
        color: #A7A7A7;
        font-size: 12px;
        text-decoration: none;
      }
    }
  }
}

.center {
  margin: auto;
}

/* My campaigns page */
.my-campaigns-p {
  .feeds-container {
    .card {
      a {
        text-decoration: none;
      }

      .card-header {
        .campaign-header-section {
          line-height: 1;

          .btn {
            line-height: 1;
          }

          padding: 1rem;

          .collapse-title {
            font-size: 12px;
            font-weight: 500;
            color: #000;
          }

          .status {
            font-size: 9px;
          }

          .created-at {
            font-size: 10px;
          }

          .c-state {
            font-size: 10px;
            color: green;
          }
        }
      }

      .campaign-status-section {
        overflow: auto;
        margin-bottom: 5px;
        /* width */

        /* width */
        &::-webkit-scrollbar {
          width: 20px;
          height: 5px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          background: #f1f1f1;
          border-radius: 10px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: #C5C5C5;
          border-radius: 5px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
          background: #b7b7b7;
        }

        padding-bottom: 20px;

        .steps .step {
          word-wrap: normal;
          width: 82px;
          .circle {
            width: 34px;
            height: 34px;
            border-radius: 50%;
            font-size: 13px;
            border: 2px solid #c4c4c4;
            text-align: center;
            line-height: 31px;
            color: #c4c4c4;
            font-weight: 600;
            overflow: hidden;

            &.success {
              background-image: url('~/images/step-tick.svg');
              background-repeat: no-repeat;
              background-color: $secondary;
              background-size: auto;
              background-position: center;
              border: 0;
            }

            &.success-text {
              line-height: 34px;
              border: 0;
              background: $secondary;
              color: #fff;
            }
          }
        }

        .step-text {
          font-size: 10px;
          font-weight: 500;
        }

        .step-line {
          min-width: 64px;
          margin: 0.8rem 0.5rem 0rem 0.5rem;
          border-radius: 1px;
          height: 1px;
        }

        .step-line-1 {
          border-bottom: 2px solid $secondary;
        }

        .step-line-0 {
          border-bottom: 2px solid #C5C5C5;
        }
      }

    }
  }
}

.collapse-title {
  line-height: 1.1;
  padding-bottom: 1px;
}

.alert-wrapper {
  z-index: 5;
  width: 50%;
  margin: auto;
}

/* Submitted draft details page */
.submitted-offer-p {
  .submitted-draft {
    line-height: 1;

    .header-section {
      .clickable {
        margin-left: 12px;
        margin-top: 2px;
      }
    }

    .file-section {
      width: 100%;

      .carousel {
        .carousel-item {
          width: 100%;
          height: 375px;
          max-height: 375px;
          text-align: center;

          img {
            width: auto;
            height: 375px;
            max-height: 375px;
          }
        }

        .carousel-indicators {
          z-index: 1;
          position: relative;

          li {
            background: $secondary;
            border-radius: 50%;
            width: 7px;
            height: 7px;
            border: 0;
          }
        }
      }

      .image-container {
        width: 100%;
        text-align: center;

        img {
          width: auto;
          height: auto;
          max-height: 375px;
          max-width: 100%;
        }
      }

      .video-container {
        .fixed-hw {
          height: 375px;
          width: 100%;
        }

        width: 100%;
        text-align: center;
        max-height: 375px;

        video {
          width: auto;
          max-width: 100%;
          height: auto;
          max-height: 375px;
        }
      }
    }
  }

  .description-section {
    line-height: 18px;
  }

  .card-body {
    font-size: 10px;

    .permalink {
      font-size: 16px;
      font-weight: 500
    }
  }

  .comments-section {
    .comment-box {
      textarea {
        height: 85px;
        font-size: 12px;
      }
    }

    .btn-primary {
      font-size: 10px;
      box-shadow: 0px 8.03052px 10.7074px rgba(181, 104, 59, 0.26);
    }

    .comment {
      line-height: 15px;

      .img-msg-body {
        /*display: flex;*/
        max-width: 80%;
        width: auto;
        margin: 0 0.25rem;

        ._preview {
          object-fit: cover;
          max-width: 100px;
          max-height: 100px;
          border: 0.2px solid #e3e3e3;
          border-radius: 5px;
        }
      }

      .video-msg-body {
        max-width: 80%;
        position: relative;
        width: auto;
        margin: 0 0.25rem;

        .video-container {
          border: 1px solid #e3e3e3;
        }

        .btn-player {
          background: #ebebeb;
          padding: 33px;
        }

        &.video-thumbnail {
          .thumbnail-img {
            border: 0.2px solid #e3e3e3;
            border-radius: 5px;
            object-fit: cover;
          }

          .btn-player {
            border-radius: none;
            border: 0;
            position: absolute;
            top: 32px;
            left: 32px;
            width: 36px;
            height: 36px;
            padding: 2px;
            background: #ebebeb;
            border-radius: 17px;
          }
        }

        video {
          width: auto;
          max-width: 100%;
          min-width: 200px;
          height: 200px;
          max-height: 427px;
        }
      }
    }
  }

  .card-footer {
    position: relative;
    bottom: 0px;
    padding-bottom: 30px;
    width: 100%;
    height: 80px;

    .btn-primary-outline {
      margin: auto;
      font-weight: 600;
      font-size: 12px;
      width: 224px;
    }
  }

  .report-issue-modal {
    textarea {
      max-height: 200px;
      border: 0;
      box-shadow: none;
    }
  }
}

/* Card / Payments page*/
.add-card-p {
  #addCardBn {
    width: 200px;
  }

  .form-control.is-valid {
    background-image: url("~/images/green-tick.svg");
  }

  .card {
    min-height: calc(100vh - 128px);
  }
}


/* Toggle Switch */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #DEDEDE;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 13px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: $secondary;
}

input:focus+.slider {
  /*box-shadow: 0 0 1px $secondary;*/
}

input:checked+.slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.label-outline {
  label {
    position: relative;
    bottom: -25px;
    left: 7px;
    background: #fff;
    padding: 0 0.2rem;
    font-size: 9px;
    font-weight: bold;
  }

  input {
    padding: 30px 20px 20px 10px;
  }
}

/* Media Queries */
@import 'media-queries';