/* Small devices (phones, 567px and down) */
@media only screen and (max-width: 567px) {
  .campaign-details-p {
    .matched-influencers-content {
      .inf-section-row {
        .profile-info {
          font-size: 2.5vw;
        }
      }
    }
  }
  .onboarding-p {
    .options {
      row-gap: 30px;
    }
    .first-p-card {
      max-width: 100%;
      ol{
        text-align: left;
        margin: auto;
        display: block;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  header.masthead .device-container {
    max-width: 100%;
    width: 100%
  }

  .onboarding-p {
    .options {
      row-gap: 30px;
    }
    .back-btn {
      top: 22px;
      z-index: 3;
    }
    .center-center {
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%);
    }
  }

  .d-grid {
    display: grid;

    &.a-4 {
      grid-template-columns: auto auto auto;
    }
  }

  #toast-container {
    right: 0;
    top: 30;
    width: 100%;

    .toast {
      margin-left: auto;
      margin-right: auto;
    }
  }

  /* Modal for small screens styling */
  .modal-backdrop {
    /*display: none;*/
  }

  .navbar {
    margin-bottom: -2px;
    z-index: 1;
  }

  .ov-flow-x {
    white-space: nowrap;
    overflow-x: auto;
    /* Hide scrollbar */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  /* Hide scrollbar on chrome*/
  .ov-flow-x::-webkit-scrollbar {
    display: none;
  }

  .main-content {
    width: 100%;
    margin-left: 0px;
    min-height: calc(100vh - 103px);
    height: -o-calc(100% - 103px);
    /* opera */
    height: -webkit-calc(100% - 103px);
    /* google, safari */
    height: -moz-calc(100% - 103px);
    /* firefox */
  }

  .sidebar-menu {
    display: block;
    margin-top: 50px;
    background: #fff;
    width: 100%;
    clear: both;
    position: relative;
    border-bottom: 1px solid #C5C5C5;

    .nav-item a {
      color: #AAAAAA
    }

    .nav-item.active {
      border-bottom: 3px solid $secondary;

      a {
        color: #000
      }
    }

    .menu {
      width: 100%;
      margin: auto;
      color: #fff;
      margin: auto;

      .menu-items {
        margin-left: 0px;
      }
    }
  }

  .alert-wrapper {
    border-radius: 0;
    width: 100%
  }

  .login-box {
    .circle-top{
      width: 281px;
      height: 281px;
      position: absolute;
      top: -225px;
      left: -81px;
      z-index: 1;
      background-color: #2f0954;
      // border: 1px solid $secondary;
      border-radius: 50%;
    }

    .circle-btm {
      width: 281px;
      height: 281px;
      position: absolute;
      bottom: -134px;
      right: -67px;
      z-index: 1;
      background-color: #2f0954;
      border-radius: 50%;
    }
  }

  .login-box .card .card-footer {
    position: relative;
    left: 0;
    width: 100%;
  }

  .login-box .card {
    width: 100%;
    min-height: calc(100vh);
  }

  .chat-p {
    .chat {
      .card-footer {
        position: fixed;
        bottom: 0;
        width: 100%;
      }

      .msg {
        display: flex;

        .video-body {
          min-width: 80%;
          max-width: 80%;
          width: 80%;
        }

        .file-msg-body {
          .chat-img {
            width: 80%;
          }

          .msg-time {
            width: 80%
          }
        }
      }
    }
  }

  .messages-p {
    #create-msg {
      width: 100%;
    }

    ._top {
      border-radius: 15px 15px 0 0;
    }

    .create-msg {
      height: 81%;
      bottom: -81%;

      &._bd-sm-radius,
      ._bd-sm-radius {
        border-radius: 15px 15px 0 0;
      }
    }
  }

  #bottom-screen {
    width: 100%;
    left: 0px;
    height: 94%;
    bottom: -100%;
  }

  .btn-center {
    margin-left: 0px;
  }

  .notifications-container.show {
    .nt-body {
      max-height: 62vh;
      height: auto;
      overflow: auto;
    }

    z-index: 16;
    display: block;
    position: fixed;
    top: 46px;
    left: 6%;
    width: 88%;
    min-height: 100px;
    max-height: 80vh;
    overflow: hidden;
    background: #fff;
    box-shadow: 1px 3px 20px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    margin-left: 0;
    margin-right: 0;
  }

  /* Hide Mobile header / Horizontal navigation */
  .hide-mobile-header,
  .influencer-analytics-report-p,
  .proposal-influencers-offers-p,
  .proposal-influencers-list-p,
  .campaign-details-p,
  .offers-list-p,
  .proposals-screen-layout,
  .accepted-proposals-p,
  .submitted-offer-p,
  .create-campaign-p,
  .campaign-p,
  .budget-influencer-payment-p,
  .campaign-published-p {
    .h-user-avatar {
      display: none;
    }

    .h-back-button {
      display: flex;
    }

    .sidebar-menu {
      display: none;
    }

    .main-content {
      margin-top: 50px;
      min-height: calc(100vh - 50px);
      height: -o-calc(100% - 50px);
      /* opera */
      height: -webkit-calc(100% - 50px);
      /* google, safari */
      height: -moz-calc(100% - 50px);
      /* firefox */
    }
  }

  .budget-influencer-payment-p {
    .payment-button-section {
      left: 0;
      right: 0;
    }
  }

  .campaign-details-p {
    .campaign-submitted-drafts-section {
      padding: 1rem 0rem 1rem 1rem;
    }

    .payment-btn-section {
      width: 100%;
      margin: auto;
    }

    .options-modal {
      ._dialog ._content {

        ._footer .btn {
          font-size: 0.8rem;
        }

        ._body .content ._title {
          font-size: 0.9rem;
        }
        ._body .content ._description {
          font-size: 0.7rem;
        }
      }

      font-size: 2.5vw;
    }
  }

  /* Mobile Menu */
  .sidenav {
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 16;
    top: 0;
    left: -768px;
    overflow-x: hidden;
    transition: left 0.7s, visibility 0.7s;
    padding-top: 60px;
    text-align: center;
    background: linear-gradient(180deg, rgba(36, 3, 69, 0.91) 35.12%, $primary 100%);

    img {
      margin-bottom: 50px;
    }

    a {
      padding: 9px;
      text-decoration: none;
      font-size: 18px;
      color: $secondary;
      font-weight: bold;
      display: block;
      transition: 0.3s;
    }

    &:hover {
      color: #f1f1f1;
    }

    .menu-logout-btn {
      margin-top: 50px;
    }

    .closebtn {
      width: 75px;

      a {
        padding: 0;
      }
    }

    .menu-logo {
      img {
        margin-left: -75px;
      }
    }
  }

  .feature-pop-up {
    display: block;
    height: 80vh;
    color: #fff;
    border-radius: 33px 33px 0px 0px;
    width: 100%;
    position: fixed;
    z-index: 1;
    left: 0;
    bottom: -75vh;
    overflow-x: hidden;
    transition: bottom 0.7s, visibility 0.7s;
    padding-top: 19px;
    text-align: center;
    background: #252525;
    transition: 0.3s;

    .button {
      width: 120px;
      height: 6px;
      background: rgba(196, 196, 196, 0.13);
      margin: auto;
      border-radius: 17px;
      margin-bottom: 20px;
    }

    .sub-heading {
      font-size: 8px;
      font-weight: 400;
    }

    .heading {
      font-size: 20px;
      font-weight: 600;
    }

    .body ul li {
      font-size: 12px;
      font-weight: 400;
      margin: 20px 0;
    }
  }

  .create-campaign-p {
    .campaign-brief-file-upload-section {
      position: relative;

      label.campaign-brief-label {
        width: 100%;
      }
    }

    .btn-next {
      width: 60%;
    }
  }

  .my-campaigns-p {
    .feeds-container {
      .campaign-status-section {
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }

  /* Drafts page */
  .submitted-offer-p {
    .card-body {
      padding: 0;
      padding-bottom: 80px;
    }

    .card-footer {
      position: fixed;
    }
  }

  /* Analytics Report page */
  .influencer-analytics-report-p {
    .main-card {
      .card-footer {
        position: fixed;
        width: 100%;
        display: block;
        margin: 0;

        .accept-reject-proposal-area {
          width: 100%;

          button {
            width: 49.999%;
          }
        }
      }
    }
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .campaign-details-p {
    .matched-influencers-content {
      .matched-profile {
        .profile-info {
          font-size: 1vw;
        }
      }
    }
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}