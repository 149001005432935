/* Buttons design */

.paypal-button {
  background: url('~/images/paypal-logo.svg') center center no-repeat;
  height: 40px;
  width: 200px;
  background-color: #FFC148;
}

.hover-button:active {
  background-color: $secondary;
  color: #fff;
}

.btn-primary{
    background : $primary;
    border: 1px solid $primary;
}

.btn-primary-outline {
  background: #fff;
  border: 1px solid $primary;
  color: $primary;
  
  &:hover,
  &:focus {
    background: $primary;
    color: $secondary;
  }
}

.btn-secondary{
    background : $secondary;
    border: 1px solid $secondary;
    color: $primary;
    &:hover, &:focus, &:active{
      color: $primary;
      background : #c5d914;
    }
}

.btn-secondary-outline {
  background: #fff;
  border: 1px solid $secondary;
  color: $secondary;
  
  &:hover, &:focus, &:active {
    background: $secondary;
    color: $primary;
  }
}

.loadmore {
  --color: #c4c4c4;
  width: 300px;
  height: 35px;
  border: 1px solid var(--color);
  border-radius: 5px;
  box-sizing: border-box;
  color: var(--color);
  margin: auto;

  &:hover {
    color: var(--color);
  }
}

.btns-2 {
  background: $secondary;
  border-radius: 7px;
  color: #fff;
  
  span {
    position: relative;
  }

  button {
    &:nth-child(1) {
      border-radius: 7px 0 0 7px;
      margin-right: -2px;
    }
    
    
    &:nth-child(3) {
      border-radius: 0 7px 7px 0;
      margin-left: -2px;
    }

    color: #fff;

    // &:hover,
    // &:focus {
    //   color: #fff;
    //   background: $secondary;
    //   border-color: $secondary1
    // }
  }
}